<template>
  <div :class="[withBorder ? 'footer-tool  border-top' : 'footer-tool']">
    <a href="#" @click="handleAlarm">报警事件</a>
    <a-divider type="vertical" />
    <a href="#" @click="handleStatus">位置状态</a>
    <alarm ref="alarmRef" />
    <status ref="statusRef" />
    <input type="hidden" id="deviceId" />
    <input type="hidden" id="deviceTime" />
  </div>
</template>
<script>
import alarm from '@/views/jt808/deviceLocation/modules/alarm'
import status from '@/views/jt808/deviceLocation/modules/status'
export default {
  name: 'MapInfoWindowFooterTool',
  components: { alarm, status },
  props: {
    withBorder: {
      type: Boolean,
      default: true
    },
    objectInfo: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    objectInfo(newVal, oldVal) {
      this.deviceInfo = newVal
    }
  },
  data() {
    return {
      deviceInfo: this.objectInfo
    }
  },
  mounted() {},
  methods: {
    setData(obj) {
      document.getElementById('deviceId').value = obj.deviceId
      document.getElementById('deviceTime').value = obj.deviceTime
    },
    handleAlarm() {
      console.log('handleAlarm')
      if (!this.deviceInfo.deviceId) {
        this.deviceInfo.deviceId = document.getElementById('deviceId').value
      }
      if (!this.deviceInfo.deviceTime) {
        this.deviceInfo.deviceTime = document.getElementById('deviceTime').value
      }
      this.$refs.alarmRef.open(this.deviceInfo, '30%')
    },
    handleStatus() {
      if (!this.deviceInfo.deviceId) {
        this.deviceInfo.deviceId = document.getElementById('deviceId').value
      }
      if (!this.deviceInfo.deviceTime) {
        this.deviceInfo.deviceTime = document.getElementById('deviceTime').value
      }
      this.$refs.statusRef.open(this.deviceInfo, '30%')
    }
  }
}
</script>
<style lang="less" scoped>
.footer-tool {
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-top {
  border-top: 1px solid #e8e8e8;
}
</style>
