var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.withBorder ? "footer-tool  border-top" : "footer-tool"] },
    [
      _c("a", { attrs: { href: "#" }, on: { click: _vm.handleAlarm } }, [
        _vm._v("报警事件"),
      ]),
      _c("a-divider", { attrs: { type: "vertical" } }),
      _c("a", { attrs: { href: "#" }, on: { click: _vm.handleStatus } }, [
        _vm._v("位置状态"),
      ]),
      _c("alarm", { ref: "alarmRef" }),
      _c("status", { ref: "statusRef" }),
      _c("input", { attrs: { type: "hidden", id: "deviceId" } }),
      _c("input", { attrs: { type: "hidden", id: "deviceTime" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }